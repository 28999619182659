












import Component from 'vue-class-component';
import { Emit, Prop, Vue } from 'vue-property-decorator';
import RoundedButtonOutLined from '@/components/partials/RoundedButtonOutlined.vue';
import ButtonType from '@/assets/buttonTypes';

@Component({
  components: {
    RoundedButtonOutLined,
  },
})
export default class BaseTableCard extends Vue {
    private Buttontype = ButtonType;

    @Prop({ default: false })
    private withResultButton!: boolean;

    @Prop({ default: true })
    private withDeleteButton!: boolean;

    @Prop({ default: true })
    private withEditButton!: boolean;

    @Emit()
    private edit() {
      // emits event
    }

    @Emit()
    private results() {
      // emits event
    }

    @Emit()
    private remove() {
      // emits event
    }
}
