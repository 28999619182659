







































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import LoadingAnimation from '@/components/partials/LoadingAnimation.vue';
import ButtonType from '@/assets/buttonTypes';

@Component({
  components: { LoadingAnimation },
})
export default class RoundedButtonFilled extends Vue {
  private ButtonType = ButtonType;

  @Prop({ required: true })
  private readonly text!: string;

  @Prop({ default: undefined })
  private icon!: Vue.Component;

  @Prop({ default: false })
  private loading!: boolean;

  @Prop({ type: Boolean, default: false })
  private readonly disabled!: boolean;

  @Prop({ required: true })
  private variant!: ButtonType;

  private beforeMount() {
    const { components } = this.$options;
    if (components) {
      components.icon = this.icon;
    }
  }

  private click() {
    if (!this.disabled) {
      this.$emit('click');
    }
  }

}
