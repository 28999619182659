










import {
  Component, Emit, Prop, Vue,
} from 'vue-property-decorator';

@Component({})
export default class Chip extends Vue {

  @Prop({ required: true })
  private readonly iconName!: string;

  @Prop({ required: true })
  private readonly iconCssClasses!: string;

  @Prop({ required: true })
  private readonly text!: string;

  @Emit()
  private closeClicked() {
    // empty
  }
}
