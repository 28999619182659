























































































import {
  Component, Emit, Prop, Vue,
} from 'vue-property-decorator';
import { directive as onClickAway } from 'vue-clickaway';
import CustomSelect, { Option } from '@/components/partials/CustomSelect.vue';
import LoadingAnimation from '@/components/partials/LoadingAnimation.vue';
import Chip from '@/components/partials/Chip.vue';
import Header from '@/assets/headers';
import TextHeader from '@/components/partials/TextHeader.vue';

@Component({
  components: {
    CustomSelect,
    LoadingAnimation,
    Chip,
    TextHeader,
  },
  directives: {
    onClickAway,
  },
})
export default class Table extends Vue {
    private Header = Header;
    private itemsPerPageOptions: Option[] = [
      {
        name: '10',
        value: 10,
      },
      {
        name: '20',
        value: 20,
      },
      {
        name: '30',
        value: 30,
      },
    ];

    private itemsPerPage: Option = {
      name: '10',
      value: 10,
    };
    private showFilterDropdown: boolean = false;

    @Prop({ default: true })
    private showFilterBar!: boolean;

    @Prop({ default: true })
    private showPagination!: boolean;

    @Prop({ required: true })
    private selectedStateFilters!:[];

    @Prop({ required: true })
    private isLoading!: boolean;

    @Prop({ required: true })
    private noItemsFoundMessage!: string;

    @Prop({ required: true })
    private currentPage!: number;

    @Prop({ required: true })
    private totalNumberOfItems!: number ;

    @Prop({ required: true })
    private rows!:unknown[];

    @Prop({ required: true })
    private filterStates!:unknown[];

    @Prop({ default: false })
    private cardsHorizontal!: boolean;

    @Prop({ default: true })
    private showCardsOnMobileViewPort!: boolean;

    private selectedStateFiltersProp = [];

    private mounted() {
      this.selectedStateFiltersProp = this.selectedStateFilters;
    }

    private async removeSelectedState(stateName: string) {
      this.selectedStateFiltersProp = this.selectedStateFiltersProp.filter((item: string) => item !== stateName);
      this.stateSelectionChanged();
    }

    @Emit()
    private stateSelectionChanged() {
      return this.selectedStateFiltersProp;
    }

    @Emit()
    private itemsPerPageChanged() {
      return this.itemsPerPage.value;
    }
    @Emit()
    private toFirstPage() {
      // emits event
    }
    @Emit()
    private previousPage() {
      // emits event
    }

    @Emit()
    private nextPage() {
      // emits event
    }
    @Emit()
    private toLastPage() {
      // emits event
    }

    private get currentItemStart(): number {
      return this.currentPage * this.itemsPerPage.value + 1;
    }

    private get currentItemEnd(): number {
      return this.currentItemStart + this.rows.length - 1;
    }

    private closeFilterDropDown() {
      this.showFilterDropdown = false;
    }

}
