















import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import Header from '@/assets/headers';
import TextHeader from '@/components/partials/TextHeader.vue';

@Component({
  components: {
    TextHeader,
  },
})
export default class TableCardEntry extends Vue {
    private Header = Header;

    @Prop({ default: 'Header' })
    private header!: string;

    @Prop({ default: 'Text' })
    private text!: string;

    @Prop({ default: () => [] })
    private textArray!: string[];

    @Prop({ default: false })
    private headerAndTextInOneLineOnXsViewport!: boolean;
}
